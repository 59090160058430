export const MAX_ITEMS_BY_PAGE = 15;

export enum OrderDirection {
  ASC = 'ascending',
  DESC = 'descending',
}
export async function queryPaginated(
  totalItems: number,
  page: number,
  query: Object,
  order?: { direction: OrderDirection; col: string; referencedTable?: string },
): Promise<unknown> {
  const itemStartIndex = page === 1 ? 0 : (page - 1) * MAX_ITEMS_BY_PAGE;
  const itemEndIndex =
    totalItems < MAX_ITEMS_BY_PAGE
      ? itemStartIndex + totalItems
      : itemStartIndex + MAX_ITEMS_BY_PAGE - 1;

  if (order) {
    query.order(order.col, {
      referencedTable: order.referencedTable,
      ascending: order.direction === OrderDirection.ASC,
    });
  }

  const { data, error } = await query.range(itemStartIndex, itemEndIndex);
  return {
    data,
    error,
    totalPages: Math.ceil(totalItems / MAX_ITEMS_BY_PAGE),
  };
}
